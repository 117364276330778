import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
import Headroom from "react-headroom";

function Header({ type }) {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div class="bg-white h-12 ">
      <Headroom
        upTolerance="0"
        downTolerance="150"
        disableInlineStyles
        className="z-20 fixed w-full p-4"
      >
        <div className="c-page__header [ w-full md:pt-0 flex justify-between z-20 ]">
          <div className="flex flex-1 content-end">
            <span className="block leading-tight">
              <Link to="/" className="mr-2">
                <strong className="text-black">
                  {site.siteMetadata.title}
                </strong>
              </Link>
              {/* <Link
                to="/sessions"
                className="absolute md:static top-0 left-0 w-full md:w-auto bg-yellow-200 text-black rounded-sm text-xs px-4 pt-2 pb-1 md:p-1 md:pb-0"
              >
                Apply for May 5th Acoustic 2020 Session shoot
              </Link> */}
            </span>
          </div>

          <ul className="flex list-none p-0 text-white text-right font-medium">
            <li className="inline-block ml-2 mr-1">
              <Link to="/sessions" className="text-black">
                Sessions
              </Link>
            </li>
            <li className="inline-block ml-4 mr-1">
              <a
                className="button button--heavy px-3 pt-2 -mt-2 pb-1"
                href="#contact"
              >
                Get in touch
              </a>
            </li>
          </ul>
        </div>
      </Headroom>
    </div>
  );
}

export default Header;
