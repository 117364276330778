import React from "react";
import { Link } from "gatsby";
import K from "../images/kaputtmacher-seal-animated.gif";

export default () => (
  <div className="bg-white relative">
    <footer className="mx-auto px-4 py-10 sm:p-10 md:p-16 ">
      <div className="flex -mx-2 leading-normal flex-wrap text-center md:text-left">
        <div className="w-full md:w-1/3 px-2 mb-8 text-sm">
          <span className="block">
            <strong>Kaputtmacher</strong>
            <br />
            hello@kaputtmacher.com
          </span>
        </div>
        <div className="w-full md:w-1/3 px-2 mb-8 text-gray-300 text-sm">
          <p>
            You should follow us
            <br />
            <a className="greenline" href="https://instagram.com/kaputtmacher/">
              instagram
            </a>
            <br />
            <a className="greenline" href="https://youtube.com/c/kaputtmacher">
              youtube
            </a>
            <br />
            <a className="greenline" href="https://facebook.com/kaputtmachern">
              facebook
            </a>
          </p>
        </div>
        <div className="w-full md:w-1/3 md:flex items-top text-gray-300 text-sm">
          <div>
            <svg
              viewBox="0 0 284 307"
              xmlns="http://www.w3.org/2000/svg"
              className="w-8 md:w-10 mx-auto mb-4"
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinejoin: "round",
                strokeMiterlimit: 2
              }}
            >
              <g>
                <path
                  d="M12.436,255.716l114.82,0l0,51.071l-63.748,0l-51.072,-51.071Z"
                  style={{ fill: "#750787" }}
                />
                <path
                  d="M157.753,255.716l92.652,0l33.256,51.071l-74.836,0l-51.072,-51.071Z"
                  style={{ fill: "#750787" }}
                />
                <path
                  d="M127.256,255.716l-114.82,0l-12.239,-12.24l63.747,0l0,-38.832l56.065,0l15.075,22.952l-7.828,11.48l0,16.64Z"
                  style={{ fill: "#004dff" }}
                />
                <path
                  d="M250.405,255.716l-92.652,0l-12.239,-12.24l74.836,0l-25.286,-38.832l22.086,0l33.255,51.072Z"
                  style={{ fill: "#004dff" }}
                />
                <path
                  d="M120.009,204.644l-56.065,0l0,-28.879l17.416,-22.192l5.106,0l33.543,51.071Z"
                  style={{ fill: "#008026" }}
                />
                <path
                  d="M217.15,204.644l-22.086,0l-33.255,-51.071l46.699,0l-13.541,17.004l22.183,34.067Z"
                  style={{ fill: "#008026" }}
                />
                <path
                  d="M86.466,153.573l-5.106,0l2.78,-3.543l2.326,3.543Z"
                  style={{ fill: "#ffed00" }}
                />
                <path
                  d="M208.508,153.573l-46.699,0l-30.153,-46.307l3.794,-4.765l113.726,0l-40.668,51.072Z"
                  style={{ fill: "#ffed00" }}
                />
                <path
                  d="M249.176,102.501l-113.726,0l40.669,-51.071l91.74,0l12.24,12.239l-30.923,38.832Z"
                  style={{ fill: "#ff8c00" }}
                />
                <path
                  d="M63.944,100.141l0,-48.711l38.272,0l-38.272,48.711Z"
                  style={{ fill: "#ff8c00" }}
                />
                <path
                  d="M102.216,51.43l-38.272,0l0,-51.072l43.904,43.903l-5.632,7.169Z"
                  style={{ fill: "#e40303" }}
                />
                <path
                  d="M267.859,51.43l-91.74,0l40.669,-51.072l51.071,51.072Z"
                  style={{ fill: "#e40303" }}
                />
              </g>
            </svg>
          </div>
          <div class="leading-tight pl-4 pr-4">
            We are inclusive, regardless of gender, sex, sexual preferences,
            race, nationality or origin.
          </div>
        </div>
      </div>

      <p className="mt-16 mb-4 text-xs text-center md:text-left text-gray-200">
        <span className="mr-2">Kaputtmacher {new Date().getFullYear()}</span>
        <Link className="greenline" to="/site-notice">
          Site Notice
        </Link>
      </p>
    </footer>
  </div>
);
