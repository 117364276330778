import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import Footer from "./footer";

function Layout({ children, type }) {
  return (
    <div className="leading-snug font-sans antialiased border-white">
      <Header type={type} />

      <main className="border-white md:border-16 md:border-b-0">
        {children}
      </main>

      <Footer type={type} />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
